import React from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoutes from "./PrivateRoutes";
import RestrictedRoutes from "./RestrictedRoutes";

import LoginIndex from "../pages/Login";
import Home from "../pages/Home";

import CreateTournament from "pages/Tournament/CreateTournament";
import ViewTournament from "pages/Tournament/ViewTournament";
import EditTournament from "pages/Tournament/EditTournament";
import TournamentIndex from "pages/Tournament/Index";

import CreateTournamentNew from "pages//TournamentNew/CreateTournament";
import TournamentIndexNew from "pages/TournamentNew/Index";
// import ViewTournamentNew from "pages/TournamentNew/ViewTournament";
// import EditTournamentNew from "pages/TournamentNew/EditTournament";

import CreateMatch from "pages/Match/CreateMatch";
import MatchIndex from "pages/Match/Index";
import ViewMatch from "pages/Match/ViewMatch";
import EditMatch from "pages/Match/EditMatch";

// NTPC STARTS
import CreateMatchNtpc from "pages/Ntpc/CreateMatch";
import MatchIndexNtpc from "pages/Ntpc/Index";
import ViewMatchNtpc from "pages/Ntpc/ViewMatch";
// NTPC ENDS

import TeamsIndex from "pages/Team/Index";
import CreateTeam from "pages/Team/CreateTeam";
import ViewTeam from "pages/Team/ViewTeam";
import EditTeam from "pages/Team/EditTeam";
import TeamPlayerList from "pages/Team/TeamPlayerList";

import ScorerIndex from "pages/Scorer/Index";
import CreateScorer from "pages/Scorer/CreateScorer";
import ViewScorer from "pages/Scorer/ViewScorer";
import EditScorer from "pages/Scorer/EditScorer";
import ScorerMatchList from "pages/Scorer/ScorerMatchList";

import GroundIndex from "pages/Grounds/Index";
import CreateGround from "pages/Grounds/CreateGround";
import ViewGround from "pages/Grounds/ViewGround";
import EditGround from "pages/Grounds/EditGround";

import PlayerIndex from "pages/Player/Index";
import CreatePlayer from "pages/Player/CreatePlayer";
import ViewPlayer from "pages/Player/ViewPlayer";
import EditPlayer from "pages/Player/EditPlayer";

import Error404 from "pages/Error404";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoutes />}>
        <Route index element={<Home />} />

        {/* Tournament routes - old */}
        <Route path="/tournament" element={<TournamentIndex />} />
        <Route path="/tournament/create" element={<CreateTournament />} />
        <Route path="/tournament/view/:id" element={<ViewTournament />} />
        <Route path="/tournament/edit" element={<EditTournament />} />

        {/* Tournament routes - new */}
        <Route path="/tournament-new" element={<TournamentIndexNew />} />
        <Route
          path="/tournament-new/create"
          element={<CreateTournamentNew formType="create" />}
        />
        <Route
          path="/tournament-new/view/:id"
          element={<CreateTournamentNew formType="view" />}
        />
        <Route
          path="/tournament-new/edit/:id"
          element={<CreateTournamentNew formType="edit" />}
        />

        {/* Match routes */}
        <Route path="/matches/:tournamentId" element={<MatchIndex />} />
        <Route path="/match/create/:tournamentId" element={<CreateMatch />} />
        <Route path="/match/view/:matchId" element={<ViewMatch />} />
        <Route path="/match/edit/:matchId" element={<EditMatch />} />

        {/* Team routes */}
        <Route path="/teams" element={<TeamsIndex />} />
        <Route path="/team/create" element={<CreateTeam />} />
        <Route path="/team/view/:teamId" element={<ViewTeam />} />
        <Route path="team/edit/:teamId" element={<EditTeam />} />
        <Route path="team/:teamId/players" element={<TeamPlayerList />} />

        {/* Scorer routes */}
        <Route path="/scorers" element={<ScorerIndex />} />
        <Route path="/scorer/create" element={<CreateScorer />} />
        <Route path="/scorer/view/:scorerId" element={<ViewScorer />} />
        <Route path="/scorer/edit/:scorerId" element={<EditScorer />} />
        <Route path="/scorer/:scorerId/matches" element={<ScorerMatchList />} />

        {/* Ground routes */}
        <Route path="/grounds" element={<GroundIndex />} />
        <Route path="/ground/create" element={<CreateGround />} />
        <Route path="/ground/view/:groundId" element={<ViewGround />} />
        <Route path="/ground/edit/:groundId" element={<EditGround />} />

        {/* Player routes */}
        <Route path="/players" element={<PlayerIndex />} />
        <Route path="/player/create" element={<CreatePlayer />} />
        <Route path="/player/view/:playerId" element={<ViewPlayer />} />
        <Route path="/player/edit/:playerId" element={<EditPlayer />} />

        {/* NTPC ROUTES */}
        <Route path="/ntpc/matches/:sportsCode" element={<MatchIndexNtpc />} />
        <Route path="/ntpc/create/" element={<CreateMatchNtpc />} />
        <Route path="/ntpc/view/:matchId" element={<ViewMatchNtpc />} />
        {/* <Route path="/ntpc/edit/:matchId" element={<EditMatchNtpc />} /> */}
      </Route>

      <Route path="/" element={<RestrictedRoutes />}>
        <Route index element={<LoginIndex />} />
        <Route path="/login" element={<LoginIndex />} />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRoutes;
